import React, { useState, useEffect } from 'react';
import { ApiResponse } from "../types/Types";
import PlayerRow from './PlayerRow';
import { Results } from '../types/Types';
import { Leaderboard } from '../types/Types';
import * as SampleData from "../components/SampleData.json";
import LastUpdated from './LastUpdated';
import CutLine from './CutLine';
import { stringToNumber } from '../types/Types';
type Props = {
    // props
}

const FetchLeaderboard = () => {
  let sampleResponseObject : ApiResponse = {
    meta: SampleData.meta,
    results: SampleData.results
  }
    const [apiResponse, setApiResponse] = useState<ApiResponse | undefined>(undefined);
    const [paymentStructure, setPaymentStructure] = useState<Record<string, number>>({});

    const [maxPaid, setMaxPaid ] = useState<number>(65);

    useEffect(() => {
      // const tournamentId = 279;
      // fetch("https://golf-leaderboard-data.p.rapidapi.com/fixtures/2/2021", {
      //       "method": "GET",
      //       "headers": {
      //         "x-rapidapi-key": "f5d0fabea7msh40386f7eed4cd32p170123jsn8fdbf447f0e9",
      //         "x-rapidapi-host": "golf-leaderboard-data.p.rapidapi.com"
      //       }
      //     })
      //     .then(response => {
      //       console.log(response);
      //     })
      //     .catch(err => {
      //       console.error(err);
      //     });
        fetch("https://golf-leaderboard-data.p.rapidapi.com/leaderboard/279", {
            "method": "GET",
            "headers": {
                "x-rapidapi-key": "f5d0fabea7msh40386f7eed4cd32p170123jsn8fdbf447f0e9",
                "x-rapidapi-host": "golf-leaderboard-data.p.rapidapi.com"
            }
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            console.log(data);
            let responseObject : ApiResponse = {
              meta: data.meta,
              results: data.results
            }
            setApiResponse(responseObject);
            
            const payoutList:stringToNumber = buildPayoutList(responseObject.results);
            const playersThroughCut = responseObject.results.leaderboard.filter((obj) => obj.status === "active").length;
            
            setMaxPaid(playersThroughCut);
            setPaymentStructure(payoutList)
        })
        .catch(err => {
            console.error(err);
        });

        // Reset for 
        // setPaymentStructure(buildPayoutList(sampleResponseObject.results))
          // setApiResponse(sampleResponseObject);
          // // build payouts w/Ties
          // setPaymentStructure(buildPayoutList(sampleResponseObject.results))
    }, [])
    return (
      <div className="Leaderboard container">
        <h2 className="Leaderboard-title">{apiResponse?.meta!.title && (apiResponse!.meta!.title)}</h2>
        <LastUpdated />
        <table className="Leaderboard-table">
          <th className="Leaderboard-th">Pos</th>
          <th className="Leaderboard-th">Golfer</th>
          <th className="Leaderboard-th">Score</th>
          <th className="Leaderboard-th">USD</th>
          {apiResponse?.results && apiResponse.results?.leaderboard.map((player :Leaderboard, index :number) => (
            <React.Fragment>
              <PlayerRow player={player} tieChart={paymentStructure} index={index} />
              <CutLine index={index} maxPaid={maxPaid-1} />
            </React.Fragment>
          ))}
        </table>
      </div>

    )
}

function buildTieList(results :Results){
  let purseTies: stringToNumber = {};
  const leaderboard = results.leaderboard;

  leaderboard.forEach((row :Leaderboard) => {
    const position = row.position;
    const madeCut = row.status == "active"
    const maxPositionsPaid = 65;
    const validPosition = maxPositionsPaid > position && madeCut;

    // build map of players in each position
    if(validPosition && purseTies[position]){
      purseTies[position]++;
    } else if (validPosition) {
      purseTies[position] = 1;
    }
  })

  return purseTies;
}

function buildPayoutList(results :Results){
  type PurseChart = Record<string, number>;

  //TODO(tredshaw): rip this from a CSV
  const purseChart: PurseChart = {
    "1":2070000,
    "2":1242000,
    "3":782000,
    "4":552000,
    "5":460000,
    "6":414000,
    "7":385250,
    "8":356500,
    "9":333500,
    "10":310500,
    "11":287500,
    "12":264500,
    "13":241500,
    "14":218500,
    "15":207000,
    "16":195500,
    "17":184000,
    "18":172500,
    "19":161000,
    "20":149500,
    "21":138000,
    "22":128800,
    "23":119600,
    "24":110400,
    "25":101200,
    "26":92000,
    "27":88550,
    "28":85100,
    "29":81650,
    "30":78200,
    "31":74750,
    "32":71300,
    "33":67850,
    "34":64975,
    "35":62100,
    "36":59225,
    "37":56350,
    "38":54050,
    "39":51750,
    "40":49450,
    "41":47150,
    "42":44850,
    "43":42550,
    "44":40250,
    "45":37950,
    "46":35650,
    "47":33350,
    "48":31510,
    "49":29900,
    "50":28980
  }

  const leaderboard = results.leaderboard;
  const maxPositionsPaid = Object.keys(purseChart).length;
  let purseTies: stringToNumber = {};
  const playersThroughCut = results.leaderboard.filter((obj) => obj.status === "active").length;
  
  leaderboard.forEach((row :Leaderboard) => {
    const position = row.position;
    const validPosition = playersThroughCut > position;
    if(validPosition && purseTies[position]){
      purseTies[position]++;
    } else if (validPosition && position > maxPositionsPaid) {

      const lastKey = Object.keys(purseTies)[Object.keys(purseTies).length-1];
      purseTies[lastKey]++;
    } else if (validPosition) {
      purseTies[position] = 1;
    }
  })
  // loop through every result and associate  
  let finalPaymentMap :stringToNumber = {};

  for (let resultIndex = 0; resultIndex < results.leaderboard.length; resultIndex++) {
    const position = results.leaderboard[resultIndex].position;

    const noTiePayment = purseChart[position.toString()];

    if (purseTies[resultIndex] == 1){
      finalPaymentMap[position.toString()] = noTiePayment;
    } else {
      const playersTied = purseTies[position];

      let splitPayout = 0;

      for (let j = 0; j < playersTied; j++){
        const index = position + j;
        if (index < maxPositionsPaid){
          splitPayout += purseChart[index.toString()]
        }
      }
      const winnings = splitPayout / playersTied; 
      if (winnings > 0){
        finalPaymentMap[position.toString()] = winnings;
      }
    }
  }
  return finalPaymentMap;
}

export default FetchLeaderboard;
