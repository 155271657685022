import React from 'react';
import FetchLeaderboard from './FetchLeaderboard';
import Header from './Header';

type Props = {
    // props
}

const Table = () => {
    return (
      <div className="Blah">
        <div>
          <Header />
          <FetchLeaderboard />
        </div>
      </div>
    )
}

export default Table;