import React from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import { stringToNumber } from '../types/Types';
type Props = {
    // props
    maxPaid: number;
    index: number;
}

const CutLine = ({ maxPaid, index } :Props) => {
    const showCutline = index == maxPaid;

    const cutRow = (
      <tr className="Leaderboard-row Leaderboard-missedCut">
        <td colSpan={4} className="Leaderboard-cutData">Cutline</td>
      </tr>
    ) 
    return showCutline ? cutRow : null;
}

export default CutLine;
