import React from 'react';
import './App.scss';
import './bootstrap.scss';
import Table from "./components/Table"

function App() {
  return (
      <div className="App">
        <Table />
      </div>
  );
}

export default App;
