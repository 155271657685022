import React from 'react';
import { Leaderboard } from '../types/Types';
import { stringToNumber } from '../types/Types';

type Props = {
    // props
    player: Leaderboard;
    tieChart: stringToNumber;
    index: number;
}

const PlayerRow = ({player, tieChart, index} :Props) => {
    const name = player.first_name + " " + player.last_name;
    const position = player.position;
    const score = player.total_to_par;
    const prettyScore = score == 0 ? 'E' : (score > 0) ? "+" + score : score;

    return (
        <tr className="Leaderboard-row">
          <td>{position}</td>
          <td>{name}</td>
          <td>{prettyScore}</td>
          <td>{renderPrize(player, tieChart)}</td>
        </tr>
    )
}

function renderPrize(player :Leaderboard, tieChart : stringToNumber){

  const currencyFormatOptions = {
    style:"currency",
    currency: "USD",
    minimumFractionDigits: 0
  }
  const madeCut = player.status == "active";
  const isLastPlace = madeCut && player.position > 65;
  const paymentIndex = (madeCut && !isLastPlace) ? player.position : (isLastPlace ? Object.keys(tieChart)[Object.keys(tieChart).length - 1] : player.position);

  const prize = tieChart[paymentIndex] ? tieChart[paymentIndex].toLocaleString("en-US", currencyFormatOptions) : player.status.toUpperCase();

  return (
    <span className="Leaderboard-prize">
      {prize}
    </span >
  )
}



export default PlayerRow;
