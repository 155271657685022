import React from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
type Props = {
    // props
}

const LastUpdated = () => {
    return (
        <div className="Update">
            <div>Last Updated: <span>{getTime()}</span></div>
        </div>
    )
}
function getTime(){
  var d = new Date();
  var n = d.toLocaleTimeString();
  return n;
}
export default LastUpdated;
